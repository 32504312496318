import React, { Suspense, lazy, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router'

/* COMPONENTS */
import Loading from './loading/loading'
import Layout from './layout/layout'
import { languageContext } from '../../src/languageContext'
import { globalActions } from '../redux/actions/global'
import { actions as projectActions } from '../redux/actions/project'
import { actions, fetchData } from '../redux/actions/auth'

import { postLog } from '../api/log'
import { postEmr } from '..//api/emr'

import PulseLoader from 'react-spinners/PulseLoader'
import { colors } from '../utils/colors'
import textsData from '../staticTexts'

import ReactGA from 'react-ga'

import { TRACKING_ID } from '../api/config'

const ErrorPage = lazy(() => import('../pages/error/errorPage'))

const App = (props) => {
  const history = useHistory()
  const [texts] = useState(textsData[16])

  useEffect(() => {
    props.setLoading(3)
  }, [])

  useEffect(() => {
    if (
      props?.selectedEntityIndex !== '' &&
      props?.selectedEntityIndex !== null
    ) {
      props?.getBuildingDetails()
    }
  }, [props?.selectedEntityIndex])

  useEffect(() => {
    if (props.token) {
      props.getEmr()
      props.resetAvgMeasuresLoading()
    }
  }, [])

  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        let keys = Object.keys(localStorage)
        let logKeys = keys.filter((key) => key.split(/[.]/)[0] === 'log')
        let emrKeys = keys.filter((key) => key.split(/[.]/)[0] === 'emr')

        if (logKeys?.length > 0) {
          logKeys.forEach(async (key) => {
            localStorage.getItem(`${key}`)
            const insertLog = await postLog(
              props.user,
              JSON.parse(localStorage.getItem(`${key}`)),
              true,
            )
            if (insertLog !== 'error') {
              localStorage.removeItem(`${key}`)
            }
          })
        }

        if (emrKeys?.length > 0) {
          emrKeys.forEach(async (key) => {
            localStorage.getItem(`${key}`)
            const insertEmr = await postEmr(
              props.user,
              JSON.parse(localStorage.getItem(`${key}`)),
              true,
            )
            if (insertEmr !== 'error') {
              localStorage.removeItem(`${key}`)
            }
          })
        }
        const itemStr = localStorage.getItem('API_REMINDER')
        if (!itemStr) {
          return null
        }
      } catch (error) {
        return 0
      }
      return 0
    }, 10000)

    return () => {
      clearInterval(interval)
    }
  }, [props.user])

  useEffect(() => {
    if (props.token) {
      if (props.allEntities.length > 0) {
        props.refreshBuildings(props.user, history)
        props.resetLoadingOfBuilding()
      }
    }
  }, [props.token])

  //* Calling the routes and checking if the buildings are being fetched
  //* Rerouting all the unavailable routes to the /error page
  //* Making sure that the user is logged in

  ReactGA.initialize(TRACKING_ID)

  const location = useLocation()
  useEffect(() => {
    ReactGA.pageview(location.pathname)
  }, [location.pathname])

  return props.loadingData >= 1 && props.loadingData < 2 ? (
    <div className="w-screen h-screen">
      <div className="grid w-full h-full p-20 place-items-center">
        <div className="grid place-items-center">
          <PulseLoader color={colors.mainBlue} loading={true} size={35} />
          <p className="text-lg text-center text-ecco2blue-100">
            {texts[props.language].loadingMsg}
          </p>
        </div>
      </div>
    </div>
  ) : (
    <languageContext.Provider
      value={{
        value: props.language,
        setValue: (value) => props.setLanguage(value),
      }}
    >
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path="/error">
            <ErrorPage />
          </Route>
          <Route path="/">
            <Layout />
          </Route>
          <Route>
            <Redirect to="/error" />
          </Route>
        </Switch>
      </Suspense>
    </languageContext.Provider>
  )
}

//* Calling redux states and actions

const mapStateToProps = (state) => ({
  token: state.auth.token,
  auth: state.auth,
  user: state.auth.userData,
  configurations: state.global.respOfAll,
  loadingData: state.global.loadingData,
  allEntities: state.global.allEntities,

  selectedEntityIndex: state.global.selectedEntityIndex,
  language: state.global.language,
})

const mapDispatchToProps = (dispatch) => ({
  fetch: (user, data, history) =>
    dispatch(fetchData.fetch(user, data, history)),
  setAllEntities: (data) => dispatch(globalActions.setAllEntities(data)),
  setLoading: (data) => dispatch(globalActions.setLoading(data)),
  resetLoading: () => dispatch(globalActions.resetLoading()),
  projectResetLoading: () => dispatch(projectActions.resetLoading()),
  loginError: (data) => dispatch(actions.loginError(data)),

  resetAvgMeasuresLoading: () =>
    dispatch(globalActions.resetAvgMeasuresLoading()),

  getBuildingDetails: () => dispatch(globalActions.getBuildingDetails()),
  resetLoadingOfBuilding: () =>
    dispatch(globalActions.resetLoadingOfBuilding()),
  getEmr: () => dispatch(globalActions.getEmrDetails()),
  setLanguage: (language) => dispatch(globalActions.setLanguage(language)),

  refreshBuildings: (data, history) =>
    dispatch(globalActions.refreshBuildingList(data, history)),
})
export default connect(mapStateToProps, mapDispatchToProps)(App)
