import fetch from 'isomorphic-fetch'
// API Responsible to get access rights
export const internetAccess = async () => {
  window.onbeforeunload = null
  // let refresher = false
  // let timer = false
  try {
    const controller = new AbortController()
    window.onbeforeunload = function () {
      // refresher = true
      controller.abort()
    }

    setTimeout(() => {
      // timer = true
      controller.abort()
    }, 300000)
    let response = await fetch(`https://www.google.com/`, {
      signal: controller.signal,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

    return { status: response.status }
  } catch (err) {
    let error = {
      status: 500,
    }
    return error
  }
}
