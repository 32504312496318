import fetch from 'isomorphic-fetch'

let { BASE_URL } = require('./config')

// API Responsible to get favorites
export const updateFavorite = async (data, userData) => {
  window.onbeforeunload = null
  // let refresher = false
  // let timer = false
  try {
    const controller = new AbortController()
    window.onbeforeunload = function () {
      // refresher = true
      controller.abort()
    }

    setTimeout(() => {
      // timer = true
      controller.abort()
    }, 300000)
    let response = await fetch(`${BASE_URL}favorites/modify`, {
      signal: controller.signal,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Basic ${window.btoa(
          `${userData.username}:${userData.password}`,
        )}`,
      },
      body: JSON.stringify({
        request: { favoriteInfo: [data] },
      }),
    })

    if (window.location.search === '?showlog=true') {
      console.log('update favorites response', response)
    }

    return response
  } catch (err) {
    let error = {
      status: 600,
    }
    return error
  }
}

export const getFavorites = async (userData) => {
  window.onbeforeunload = null
  // let refressher = false
  // let timer = false

  try {
    const controller = new AbortController()
    window.onbeforeunload = function () {
      // refresher = true
      controller.abort()
    }

    setTimeout(() => {
      // timer = true
      controller.abort()
    }, 300000)

    let response = await fetch(`${BASE_URL}favorites/list`, {
      signal: controller.signal,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Basic ${window.btoa(
          `${userData.username}:${userData.password}`,
        )}`,
      },
    })

    if (window.location.search === '?showlog=true') {
      console.log('get favorites response', response)
    }
    return response
  } catch (err) {
    let error = {
      status: 600,
    }
    return error
  }
}
