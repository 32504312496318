import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { configurePersistor, configureStore } from './redux/store/store'

import App from './components/App'

import './assets/styles/style.css'

//* Redux setup
const store = configureStore()
const persistor = configurePersistor(store)
const wrapper = document.getElementById('root')

//* Calling the app
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<div />} persistor={persistor}>
      <Router>
        <App />
      </Router>
    </PersistGate>
  </Provider>,
  wrapper,
)
