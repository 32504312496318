export const FAVORITES_SET = 'FAVORITES_SET'
export const FAVORITES_GET = 'FAVORITES_GET'

import { updateFavorite, getFavorites } from '../../api/favorites'

export const actions = {
  setFavorites: (data) => async (dispatch, getState) => {
    const userData = getState()?.auth?.userData

    let final = {
      ...data,
      isFavorite: data.isFavorite,
    }

    const response = await updateFavorite(final, userData)

    if (response.status === 200) {
      dispatch({
        type: FAVORITES_SET,
        payload: {
          ...data,
          state: data.isFavorite,
        },
      })
    }
  },

  getFavorites: () => async (dispatch, getState) => {
    const userData = getState()?.auth?.userData

    const response = await getFavorites(userData)

    if (response.status === 200) {
      let result = await response.json()
      dispatch({
        type: FAVORITES_GET,
        payload: result?.payload || [],
      })
    }
  },
}
