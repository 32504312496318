import React from 'react'
import { NavLink } from 'react-router-dom'

const Navigation = ({ title, active, change, text }) => {
  return (
    <NavLink
      to={`/${title}`}
      isActive={(match) => {
        if (match) {
          change(title)
        }
      }}
      className={`${
        active === title
          ? 'text-white font-semibold text-s lg:text-xl break-all uppercase'
          : 'hidden'
      }`}
      style={{ fontSize: '18px', lineHeight: '22px' }}
    >
      {text}
    </NavLink>
  )
}

export default Navigation
