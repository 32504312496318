import {
  RESET_BUILDING_AVG_MEASURES_RETRY,
  RESET_BUILDING_LOADING_RESET,
  RELOAD_DATA,
  MAIN_ENTITIES_SET,
  MAIN_ENTITIES_RESET,
  ENTITY_SET,
  ENTITY_RESET,
  SUB_ENTITY_SET,
  SUB_ENTITY_RESET,
  MENU_STATE_SET_BLDG,
  MENU_STATE_SET_PROJ,
  MENU_STATE_RESET,
  INDEX_SET,
  INDEX_RESET,
  SUBINDEX_SET,
  SUBINDEX_RESET,
  PREV_PAGE_SET,
  PREV_PAGE_RESET,
  LOADING_SET,
  LOADING_RESET,
  DATA_ERROR_SET,
  DATA_ERROR_RESET,
  EMR_DETAILS_SET,
  EMR_SET,
  EMR_RESET,
  MAIN_ENTITES_RESHAPE,
  MAIN_MEASUREMENTS_SET,
  MAIN_MEASUREMENTS_RESET,
  MAIN_MEASUREMENTS_RESHAPE,
  HEATINGGROUPS_RESHAPE,
  APARTMENTS_7D_RESHAPE,
  BUILDING_LOG_RESHAPE,
  BUILDING_7D_RESHAPE,
  APARTMENT_MEASURES_SET,
  APARTMENT_MEASURES_RESET,
  SUB_IDENTIFIER_SET,
  SUB_IDENTIFIER_RESET,
  APARTMENT_FROM_SEARCH,
  SET_LANGUAGE,
  TEMPORARY_OFFSET_RESHAPE,
  AVG_MEASURES_RESETLOADING,
  ACCESS_RIGHTS_SET,
} from '../actions/global'

import { FAVORITES_SET, FAVORITES_GET } from '../actions/favorites'
const detectLanguage = () => {
  let language = navigator.language || navigator.userLanguage
  let lang = 1
  if (language.startsWith('cs')) {
    lang = 4
  } else if (language.startsWith('fr')) {
    lang = 2
  } else if (language.startsWith('en')) {
    lang = 1
  } else if (language.startsWith('de')) {
    lang = 3
  }
  return lang
}

const initialState = {
  allEntities: [],
  entity: {},
  subEntity: {},
  selectedEntityIndex: null,
  subEntityIndex: null,
  menuState: null,
  loadingData: 999,
  dataError: [],
  prevPage: null,
  respOfAll: [],
  emrIdentifiers: [],
  buildingsAvgMeasures: [],
  apartmentsMeasures: [],

  subIdentifier: null,

  emrSettings: { loading: false, expiery: '' },

  language: detectLanguage(),
}

const temporaryOffset = (prevState, payload) => {
  let final = []
  for (let i = 0; i < prevState?.length; i++) {
    if (i === payload.index) {
      final.push({
        ...prevState[i],
        temporaryOffsetSettings: payload?.data?.settings,
        temporaryOffset: payload?.data?.data
          ? payload?.data?.data
          : prevState[i]?.temporaryOffset,
      })
    } else {
      final.push({ ...prevState[i] })
    }
  }
  return final
}
// setting the new value on a specific index while taking into consideration the previous state in order to avoid lost data
const measurments24Set = (prevState, payload) => {
  let final = []
  for (let i = 0; i < prevState?.length; i++) {
    if (i === payload?.index) {
      final.push({
        ...prevState[i],
        settings: payload?.data?.settings,
        data: payload?.data?.data ? payload?.data?.data : prevState[i]?.data,
      })
    } else {
      final.push({ ...prevState[i] })
    }
  }
  return final
}

// setting the new value on a specific index while taking into consideration the previous state in order to avoid lost data
const heatingGroupsSet = (prevState, payload) => {
  let final = []
  for (let i = 0; i < prevState?.length; i++) {
    if (i === payload?.index) {
      final.push({
        ...prevState[i],
        heatingGroupsSettings: payload?.data?.settings,
        heatingGroups: payload?.data?.heatingGroups
          ? payload?.data?.heatingGroups
          : prevState[i]?.heatingGroups,
      })
    } else {
      final.push({ ...prevState[i] })
    }
  }
  return final
}

// setting the new value on a specific index while taking into consideration the previous state in order to avoid lost data
const apartments7DSet = (prevState, payload) => {
  let final = []
  for (let i = 0; i < prevState?.length; i++) {
    if (i === payload?.index) {
      final.push({
        ...prevState[i],
        apartmentsSettings: payload?.data?.settings,
        apartments: payload?.data?.data
          ? payload.data.data
          : prevState[i].apartments,
      })
    } else {
      final.push({ ...prevState[i] })
    }
  }
  return final
}

// setting the new value on a specific index while taking into consideration the previous state in order to avoid lost data
const buildingLogsSet = (prevState, payload) => {
  let final = []
  for (let i = 0; i < prevState?.length; i++) {
    if (i === payload?.index) {
      final.push({
        ...prevState[i],
        logsSettings: payload?.data?.settings,
        logs: payload?.data?.data ? payload.data.data : prevState[i].logs,
      })
    } else {
      final.push({ ...prevState[i] })
    }
  }
  return final
}

// setting the new value on a specific index while taking into consideration the previous state in order to avoid lost data
const buildingHistoryMeasures = (prevState, payload) => {
  let final = []
  for (let i = 0; i < prevState?.length; i++) {
    if (i === payload?.index) {
      final.push({
        ...prevState[i],
        historyMeasuresSettings: payload?.data?.settings,
        historyMeasures: payload?.data?.data
          ? payload.data.data
          : prevState[i].historyMeasures,
      })
    } else {
      final.push({ ...prevState[i] })
    }
  }
  return final
}

// setting the new value on a specific index while taking into consideration the previous state in order to avoid lost data
const apartmentsMeasures = (prevState, payload) => {
  let final = []
  for (let i = 0; i < prevState?.apartmentsMeasures?.length; i++) {
    if (
      prevState?.apartmentsMeasures[i].apartmentIdentifier ===
      payload.identifier
    ) {
      final.push({
        ...prevState?.apartmentsMeasures[i],
        settings: payload.data.settings,
        measures: payload?.data?.data
          ? apartmentNullValueHandle(
              prevState?.apartmentsMeasures[i].measures,
              payload?.data?.data,
            )
          : prevState?.apartmentsMeasures[i].measures,
      })
    } else {
      final.push({ ...prevState.apartmentsMeasures[i] })
    }
  }
  return final
}

// taking previous value if current value is empty checking on humidity and temperature because not all apartments have
const apartmentNullValueHandle = (prevSate, currentState) => {
  let schema = {}
  if (
    currentState?.humidity &&
    currentState?.humidity?.value === null &&
    currentState?.temperature &&
    currentState?.temperature?.value === null
  ) {
    if (prevSate?.humidity && prevSate?.temperature) {
      schema = prevSate || {}
    } else {
      schema = currentState
    }
  } else {
    schema = currentState
  }
  return schema
}

// setting the new value on a specific index while taking into consideration the previous state in order to avoid lost data
const apartmentsFromSearch = (prevState, payload) => {
  let isInserted = false
  for (let i = 0; i < prevState?.apartmentsMeasures?.length; i++) {
    if (
      prevState?.apartmentsMeasures[i]?.apartmentIdentifier ===
      payload?.apartmentIdentifier
    ) {
      isInserted = true
    }
  }
  if (isInserted === true) {
    return prevState?.apartmentsMeasures
  } else {
    let final = prevState?.apartmentsMeasures || []
    final.push({
      ...payload,
      settings: { loading: false, expiery: '' },
      measures: {},
    })
    return final
  }
}

// setting the new value on a specific index while taking into consideration the previous state in order to avoid lost data
const emrDetails = (prevState, payload) => {
  let final = []
  for (let i = 0; i < prevState?.length; i++) {
    if (prevState[i].EMRs?.length > 0) {
      let emrFinal = []
      // ITERATING EMR IDENTIFIERS
      for (let k = 0; k < prevState[i]?.EMRs?.length; k++) {
        // ITERATING INCOMING RESPONSE
        for (let j = 0; j < payload?.length; j++) {
          // CHECKING IF IDENTIFIERS ARE THE SAME
          if (payload[j]?.identifier === prevState[i].EMRs[k]?.identifier) {
            emrFinal.push(payload[j])
          }
        }
      }
      final.push({ ...prevState[i], emrDetails: emrFinal })
    } else {
      final.push(prevState[i])
    }
  }
  return final
}

// On favorite pressed this function will be triggered
const handleFavoritesUpdate = (prevState, payload) => {
  let final = []
  for (let i = 0; i < prevState?.length; i++) {
    if (
      prevState[i].projectIdentifier === payload.projectIdentifier &&
      prevState[i].buildingIdentifier === payload.identifier
    ) {
      final.push({ ...prevState[i], isFavorite: payload.state })
    } else {
      final.push({ ...prevState[i] })
    }
  }
  return final
}

// On Favorites page initialized
const handleFavoritesGet = (prevState, payload) => {
  let final = []
  for (let i = 0; i < prevState?.length; i++) {
    let matchCount = 0
    for (let j = 0; j < payload?.length; j++) {
      if (
        prevState[i].projectIdentifier ===
          payload[j].HoldingProjectIdentifier &&
        prevState[i].buildingIdentifier === payload[j].Identifier
      ) {
        matchCount++
      }
    }
    if (matchCount > 0) {
      final.push({ ...prevState[i], isFavorite: true })
    } else {
      final.push({ ...prevState[i], isFavorite: false })
    }
  }

  return final
}

const resetAvgBuildingMeasuresLoading = (prevstate) => {
  let final = []
  for (let i = 0; i < prevstate?.length; i++) {
    final.push({
      ...prevstate[i],
      settings: { ...prevstate[i]?.settings, loading: false },
    })
  }
  return final
}

const handleAccessRights = (prevState, payload) => {
  let final = []
  for (let i = 0; i < prevState?.length; i++) {
    if (i === payload?.index) {
      final.push({
        ...prevState[i],
        accessRights: payload?.data
          ? payload.data
          : prevState[i].accessRights || [],
      })
    } else {
      final.push({ ...prevState[i] })
    }
  }
  return final
}

const reloadData = (prevSate, payload) => {
  let newAllEntities = []

  let entitiesToAdd = []
  payload.mainData?.map((newEntity, index) => {
    let subCount = 0
    prevSate?.allEntities?.map((oldEntity) => {
      if (newEntity.buildingIdentifier === oldEntity.buildingIdentifier) {
        subCount++
      }
    })
    if (subCount === 0) {
      entitiesToAdd.push(index)
    }
  })

  prevSate?.allEntities?.map((oldEntity) => {
    payload?.mainData?.map((newEntity) => {
      if (newEntity.buildingIdentifier === oldEntity.buildingIdentifier) {
        newAllEntities.push({
          ...oldEntity,
          sensorCount: newEntity.sensorCount,
          projectName: newEntity.projectName || '',
        })
      }
    })
  })

  entitiesToAdd?.map((add) => {
    newAllEntities.push(payload.mainData[add])
  })

  return newAllEntities
}

const prepareResetRetriesData = (data) => {
  let final = []
  data?.map((retry) => {
    final.push({ ...retry, settings: { ...retry.settings, retry: 0 } })
  })
  return final
}

const resetBuildingLoading = (data) => {
  let final = []
  data?.map((entity) => {
    final.push({
      ...entity,
      apartmentsSettings: { ...entity.apartmentsSettings, loading: false },
      heatingGroupsSettings: {
        ...entity.heatingGroupsSettings,
        loading: false,
      },
      historyMeasuresSettings: {
        ...entity.historyMeasuresSettings,
        loading: false,
      },
    })
  })
  return final
}

const global = (state = initialState, action) => {
  switch (action.type) {
    case RESET_BUILDING_AVG_MEASURES_RETRY:
      return Object.assign({}, state, {
        buildingsAvgMeasures: prepareResetRetriesData([
          ...state.buildingsAvgMeasures,
        ]),
      })
    case RESET_BUILDING_LOADING_RESET:
      return Object.assign({}, state, {
        allEntities: resetBuildingLoading([...state.allEntities]),
      })
    case RELOAD_DATA:
      return Object.assign({}, state, {
        allEntities: reloadData(state, action.payload),
      })
    case FAVORITES_SET:
      return Object.assign({}, state, {
        allEntities: handleFavoritesUpdate(
          [...state.allEntities],
          action.payload,
        ),
      })

    case FAVORITES_GET:
      return Object.assign({}, state, {
        allEntities: handleFavoritesGet([...state.allEntities], action.payload),
      })

    case ACCESS_RIGHTS_SET:
      return Object.assign({}, state, {
        allEntities: handleAccessRights([...state.allEntities], action.payload),
      })
    case SET_LANGUAGE:
      return Object.assign({}, state, {
        language: action.payload,
      })
    case MAIN_ENTITIES_SET:
      return Object.assign({}, state, {
        allEntities: action.payload.allEntities,
        emrIdentifiers: action.payload.emrIdentifiers,
      })
    case MAIN_ENTITIES_RESET:
      return Object.assign({}, state, {
        allEntities: action.payload,
      })

    case MAIN_MEASUREMENTS_SET:
      return Object.assign({}, state, {
        buildingsAvgMeasures: action.payload,
      })

    case MAIN_MEASUREMENTS_RESHAPE:
      return Object.assign({}, state, {
        buildingsAvgMeasures: measurments24Set(
          [...state.buildingsAvgMeasures],
          action.payload,
        ),
      })

    case HEATINGGROUPS_RESHAPE:
      return Object.assign({}, state, {
        allEntities: heatingGroupsSet([...state.allEntities], action.payload),
      })

    case TEMPORARY_OFFSET_RESHAPE:
      return Object.assign({}, state, {
        allEntities: temporaryOffset([...state.allEntities], action.payload),
      })

    case APARTMENTS_7D_RESHAPE:
      return Object.assign({}, state, {
        allEntities: apartments7DSet([...state.allEntities], action.payload),
      })

    case BUILDING_LOG_RESHAPE:
      return Object.assign({}, state, {
        allEntities: buildingLogsSet([...state.allEntities], action.payload),
      })

    case BUILDING_7D_RESHAPE:
      return Object.assign({}, state, {
        allEntities: buildingHistoryMeasures(
          [...state.allEntities],
          action.payload,
        ),
      })

    case APARTMENT_MEASURES_SET:
      return Object.assign({}, state, {
        apartmentsMeasures: apartmentsMeasures({ ...state }, action.payload),
      })

    case APARTMENT_FROM_SEARCH:
      return Object.assign({}, state, {
        apartmentsMeasures: apartmentsFromSearch({ ...state }, action.payload),
      })

    case EMR_DETAILS_SET:
      return Object.assign({}, state, {
        allEntities: emrDetails([...state.allEntities], action.payload),
      })

    case AVG_MEASURES_RESETLOADING:
      return Object.assign({}, state, {
        buildingsAvgMeasures: resetAvgBuildingMeasuresLoading([
          ...state.buildingsAvgMeasures,
        ]),
      })
    case MAIN_MEASUREMENTS_RESET:
      return Object.assign({}, state, {
        buildingsAvgMeasures: [],
      })

    case MAIN_ENTITES_RESHAPE:
      return Object.assign({}, state, {
        allEntities: action.payload,
      })

    case EMR_SET:
      return Object.assign({}, state, {
        emrSettings: action.payload,
      })

    case EMR_RESET:
      return Object.assign({}, state, {
        emrSettings: { loading: false, expiery: '' },
      })

    case SUB_IDENTIFIER_RESET:
      return Object.assign({}, state, {
        subIdentifier: null,
      })

    case APARTMENT_MEASURES_RESET:
      return Object.assign({}, state, {
        apartmentsMeasures: [],
      })

    case SUB_IDENTIFIER_SET:
      return Object.assign({}, state, {
        subIdentifier: action.payload,
      })

    case ENTITY_SET:
      return Object.assign({}, state, {
        entity: action.payload.entity,
      })
    case ENTITY_RESET:
      return Object.assign({}, state, {
        entity: {},
      })
    case SUB_ENTITY_SET:
      return Object.assign({}, state, {
        subEntity: action.payload.subEntity,
      })
    case SUB_ENTITY_RESET:
      return Object.assign({}, state, {
        subEntity: {},
      })
    case MENU_STATE_SET_BLDG:
      return Object.assign({}, state, {
        menuState: 'bldg',
      })
    case MENU_STATE_SET_PROJ:
      return Object.assign({}, state, {
        menuState: 'proj',
      })
    case MENU_STATE_RESET:
      return Object.assign({}, state, {
        menuState: null,
      })
    case INDEX_SET:
      return Object.assign({}, state, {
        selectedEntityIndex: action.payload.selectedEntityIndex,
      })
    case INDEX_RESET:
      return Object.assign({}, state, {
        selectedEntityIndex: null,
        subEntityIndex: null,
      })
    case SUBINDEX_SET:
      return Object.assign({}, state, {
        subEntityIndex: action.payload.subEntityIndex,
      })
    case SUBINDEX_RESET:
      return Object.assign({}, state, {
        subEntityIndex: null,
      })
    case PREV_PAGE_SET:
      return Object.assign({}, state, {
        prevPage: action.payload.prevPage,
      })
    case PREV_PAGE_RESET:
      return Object.assign({}, state, {
        prevPage: null,
      })
    case LOADING_SET:
      return Object.assign({}, state, {
        loadingData: action.payload.loadingData,
      })
    case LOADING_RESET:
      return Object.assign({}, state, {
        loadingData: 999,
      })
    case DATA_ERROR_SET:
      return Object.assign({}, state, {
        dataError: action.payload.dataError,
      })
    case DATA_ERROR_RESET:
      return Object.assign({}, state, {
        dataError: [],
      })
    default:
      return state
  }
}

export default global
