import React from 'react'
import DotLoader from 'react-spinners/DotLoader'
import { colors } from '../../utils/colors'

const Loading = ({ msg, loading = true }) => {
  return (
    <div className="h-full w-full fixed">
      <div className="relative top-1/4 flex justify-center items-center">
        <div>
          <div className="grid place-items-center">
            <DotLoader color={colors.mainBlue} loading={loading} size={35} />
          </div>

          <p
            style={{ fontFamily: 'segoeUIBold' }}
            className="text-lg font-black text-ecco2blue-100 text-center"
          >
            {msg}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Loading
