import React from 'react'
export const getSvg = (name, color, width, height) => {
  switch (name) {
    case 'menuIcon':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 21 17"
        >
          <g
            id="Group_64"
            data-name="Group 64"
            transform="translate(-397.5 -886.713)"
          >
            <line
              id="Line_15"
              data-name="Line 15"
              x2="18"
              transform="translate(399 888.213)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeWidth="3"
            />
            <line
              id="Line_15-2"
              data-name="Line 15"
              x2="18"
              transform="translate(399 895.213)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeWidth="3"
            />
            <line
              id="Line_15-3"
              data-name="Line 15"
              x2="18"
              transform="translate(399 902.213)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeWidth="3"
            />
          </g>
        </svg>
      )
    case 'backArrow':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 20 20"
          fill={color}
        >
          <path
            fillRule="evenodd"
            d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
            clipRule="evenodd"
          />
        </svg>
      )
    case 'emrIconOld':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 22.414 25.825"
        >
          <g id="electric-meter" transform="translate(0.25 0.25)">
            <g id="Group_50" data-name="Group 50" transform="translate(0)">
              <g id="Group_49" data-name="Group 49" transform="translate(0)">
                <path
                  id="Path_48"
                  data-name="Path 48"
                  d="M52.827,8.327A.42.42,0,0,1,52.41,7.9V1.957A1.949,1.949,0,0,0,50.474,0H35.57a1.949,1.949,0,0,0-1.936,1.957V7.9a.42.42,0,0,1-.417.422,1.164,1.164,0,0,0,0,2.328.42.42,0,0,1,.417.422v5.948A1.954,1.954,0,0,0,35.2,18.945v5.216a1.159,1.159,0,0,0,1.151,1.164H49.69a1.159,1.159,0,0,0,1.151-1.164V18.945a1.955,1.955,0,0,0,1.569-1.921V11.077a.42.42,0,0,1,.417-.422,1.164,1.164,0,0,0,0-2.328ZM33.634,9.992a1.134,1.134,0,0,0-.417-.08.422.422,0,0,1,0-.844,1.134,1.134,0,0,0,.417-.08ZM50.107,24.161a.42.42,0,0,1-.418.422H36.354a.42.42,0,0,1-.417-.422v-5.18h.835v2.4a.764.764,0,0,0,.759.767H48.513a.764.764,0,0,0,.759-.767v-2.4h.835ZM39.492,20.567a.369.369,0,0,0,.367-.371V18.981h1.619V20.2a.367.367,0,1,0,.734,0V18.981h1.619V20.2a.367.367,0,1,0,.734,0V18.981h1.619V20.2a.367.367,0,1,0,.734,0V18.981h1.619v2.4a.025.025,0,0,1-.025.026H37.531a.025.025,0,0,1-.025-.026v-2.4h1.619V20.2A.369.369,0,0,0,39.492,20.567Zm10.982-2.328H35.57a1.21,1.21,0,0,1-1.2-1.215V1.957A1.21,1.21,0,0,1,35.57.742h14.9a1.21,1.21,0,0,1,1.2,1.215V17.024h0A1.21,1.21,0,0,1,50.474,18.239Zm2.353-8.327a1.134,1.134,0,0,0-.417.08v-1a1.134,1.134,0,0,0,.417.08.422.422,0,0,1,0,.844Z"
                  transform="translate(-32.065)"
                  fill={color}
                  stroke={color}
                  strokeWidth="0.5"
                />
              </g>
            </g>
            <g
              id="Group_52"
              data-name="Group 52"
              transform="translate(3.016 2.176)"
            >
              <g id="Group_51" data-name="Group 51">
                <path
                  id="Path_49"
                  data-name="Path 49"
                  d="M117.672,40.081H104.615a.407.407,0,0,0-.407.407V56.156a.407.407,0,0,0,.407.407h.87a.407.407,0,1,0,0-.814h-.463V40.9h12.649a.407.407,0,0,0,0-.814Z"
                  transform="translate(-104.208 -40.081)"
                  fill={color}
                  stroke={color}
                  strokeWidth="0.5"
                />
              </g>
            </g>
            <g
              id="Group_54"
              data-name="Group 54"
              transform="translate(5.027 2.176)"
            >
              <g id="Group_53" data-name="Group 53" transform="translate(0)">
                <path
                  id="Path_50"
                  data-name="Path 50"
                  d="M165.769,40.081h-.87a.407.407,0,1,0,0,.814h.463V55.748H152.713a.407.407,0,1,0,0,.814h13.056a.407.407,0,0,0,.407-.407V40.488A.407.407,0,0,0,165.769,40.081Z"
                  transform="translate(-152.306 -40.081)"
                  fill={color}
                  stroke={color}
                  strokeWidth="0.5"
                />
              </g>
            </g>
            <g
              id="Group_56"
              data-name="Group 56"
              transform="translate(5.327 6.257)"
            >
              <g id="Group_55" data-name="Group 55" transform="translate(0)">
                <path
                  id="Path_51"
                  data-name="Path 51"
                  d="M162.723,128.258h-9.575a.843.843,0,0,0-.842.842v1.741a.843.843,0,0,0,.842.842h9.575a.843.843,0,0,0,.842-.842V129.1A.843.843,0,0,0,162.723,128.258Zm-8.676,2.611h-.9a.028.028,0,0,1-.028-.028V129.1a.028.028,0,0,1,.028-.028h.9Zm1.741,0h-.926v-1.8h.926Zm1.741,0H156.6v-1.8h.926Zm1.741,0h-.926v-1.8h.926Zm1.741,0h-.926v-1.8h.926Zm1.741-.028a.028.028,0,0,1-.028.028h-.9v-1.8h.9a.028.028,0,0,1,.028.028Z"
                  transform="translate(-152.306 -128.258)"
                  fill={color}
                  stroke={color}
                  strokeWidth="0.5"
                />
              </g>
            </g>
            <g
              id="Group_58"
              data-name="Group 58"
              transform="translate(5.808 10.6)"
            >
              <g id="Group_57" data-name="Group 57" transform="translate(0)">
                <path
                  id="Path_52"
                  data-name="Path 52"
                  d="M153.584,216.436a1.278,1.278,0,1,0,1.278,1.278A1.279,1.279,0,0,0,153.584,216.436Zm0,1.741a.463.463,0,1,1,.463-.463A.464.464,0,0,1,153.584,218.177Z"
                  transform="translate(-152.306 -216.436)"
                  fill={color}
                  stroke={color}
                  strokeWidth="0.5"
                />
              </g>
            </g>
            <g
              id="Group_60"
              data-name="Group 60"
              transform="translate(13.551 10.6)"
            >
              <g id="Group_59" data-name="Group 59" transform="translate(0)">
                <path
                  id="Path_53"
                  data-name="Path 53"
                  d="M313.906,216.436a1.278,1.278,0,1,0,1.278,1.278A1.279,1.279,0,0,0,313.906,216.436Zm0,1.741a.463.463,0,1,1,.463-.463A.464.464,0,0,1,313.906,218.177Z"
                  transform="translate(-312.628 -216.436)"
                  fill={color}
                  stroke={color}
                  strokeWidth="0.5"
                />
              </g>
            </g>
            <g
              id="Group_62"
              data-name="Group 62"
              transform="translate(9.679 10.6)"
            >
              <g id="Group_61" data-name="Group 61" transform="translate(0)">
                <path
                  id="Path_54"
                  data-name="Path 54"
                  d="M233.745,216.436a1.278,1.278,0,1,0,1.278,1.278A1.279,1.279,0,0,0,233.745,216.436Zm0,1.741a.463.463,0,1,1,.463-.463A.464.464,0,0,1,233.745,218.177Z"
                  transform="translate(-232.467 -216.436)"
                  fill={color}
                  stroke={color}
                  strokeWidth="0.5"
                />
              </g>
            </g>
          </g>
        </svg>
      )
    case 'emrIcon':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="ionicon"
          viewBox="0 0 512 512"
          width={width}
          height={height}
        >
          <title>EMR</title>
          <path
            d="M326.1 231.9l-47.5 75.5a31 31 0 01-7 7 30.11 30.11 0 01-35-49l75.5-47.5a10.23 10.23 0 0111.7 0 10.06 10.06 0 012.3 14z"
            fill={color}
          />
          <path
            d="M256 64C132.3 64 32 164.2 32 287.9a223.18 223.18 0 0056.3 148.5c1.1 1.2 2.1 2.4 3.2 3.5a25.19 25.19 0 0037.1-.1 173.13 173.13 0 01254.8 0 25.19 25.19 0 0037.1.1l3.2-3.5A223.18 223.18 0 00480 287.9C480 164.2 379.7 64 256 64z"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="32"
          />
          <path
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="32"
            d="M256 128v32M416 288h-32M128 288H96M165.49 197.49l-22.63-22.63M346.51 197.49l22.63-22.63"
          />
        </svg>
      )
    case 'alarmIcon':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 24.385 28.362"
        >
          <g id="notifications-outline" transform="translate(1.03 1)">
            <path
              id="Path_42"
              data-name="Path 42"
              d="M101.881,67.228c-1.627-1.992-2.776-3.006-2.776-8.5,0-5.028-2.568-6.82-4.681-7.69a1.129,1.129,0,0,1-.631-.669,2.826,2.826,0,0,0-5.58,0,1.118,1.118,0,0,1-.631.667c-2.116.871-4.681,2.656-4.681,7.69,0,5.491-1.152,6.5-2.779,8.5a1.278,1.278,0,0,0,1.1,2.064h19.574A1.278,1.278,0,0,0,101.881,67.228Z"
              transform="translate(-79.839 -48)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <path
              id="Path_43"
              data-name="Path 43"
              d="M200.111,384v1.014a4.056,4.056,0,0,1-8.111,0V384"
              transform="translate(-184.892 -362.708)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
          </g>
        </svg>
      )
    case 'waterDropIcon':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 14.488 19.695"
        >
          <g id="water-outline" transform="translate(-111 -54.925)">
            <path
              id="Path_39"
              data-name="Path 39"
              d="M124.488,67.376c0,3.832-2.412,6.244-6.244,6.244S112,71.208,112,67.376c0-4.112,4.476-9.663,5.849-11.269a.52.52,0,0,1,.79,0C120.012,57.713,124.488,63.264,124.488,67.376Z"
              transform="translate(0)"
              fill="none"
              stroke={color}
              strokeMiterlimit="10"
              strokeWidth="2"
            />
            <path
              id="Path_40"
              data-name="Path 40"
              d="M275.122,328A3.122,3.122,0,0,1,272,331.122"
              transform="translate(-153.062 -260.277)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
          </g>
        </svg>
      )
    case 'temperatureIcon':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 9.503 20.834"
        >
          <g id="thermometer-outline" transform="translate(-159.501 -31.5)">
            <path
              id="Path_41"
              data-name="Path 41"
              d="M166.543,43.967a.354.354,0,0,1-.165-.3V34.125A2.125,2.125,0,0,0,164.252,32h0a2.125,2.125,0,0,0-2.125,2.125v9.543a.354.354,0,0,1-.164.3,4.318,4.318,0,0,0-1.957,3.811,4.251,4.251,0,0,0,8.5-.192A4.317,4.317,0,0,0,166.543,43.967Z"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="1"
            />
            <line
              id="Line_9"
              data-name="Line 9"
              y2="11.399"
              transform="translate(164.253 35.857)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="1"
            />
            <ellipse
              id="Ellipse_4"
              data-name="Ellipse 4"
              cx="2.28"
              cy="1.9"
              rx="2.28"
              ry="1.9"
              transform="translate(161.974 45.736)"
              fill={color}
            />
          </g>
        </svg>
      )
    case 'buildingsIcon':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 512 512"
        >
          <title>Business</title>
          <path
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="32"
            d="M176 416v64M80 32h192a32 32 0 0132 32v412a4 4 0 01-4 4H48h0V64a32 32 0 0132-32zM320 192h112a32 32 0 0132 32v256h0-160 0V208a16 16 0 0116-16z"
          />
          <path
            fill={color}
            d="M98.08 431.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM98.08 351.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM98.08 271.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM98.08 191.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM98.08 111.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM178.08 351.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM178.08 271.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM178.08 191.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM178.08 111.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM258.08 431.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM258.08 351.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM258.08 271.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79z"
          />
          <ellipse
            fill={color}
            cx="256"
            cy="176"
            rx="15.95"
            ry="16.03"
            transform="rotate(-45 255.99 175.996)"
          />
          <path
            fill={color}
            d="M258.08 111.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM400 400a16 16 0 1016 16 16 16 0 00-16-16zM400 320a16 16 0 1016 16 16 16 0 00-16-16zM400 240a16 16 0 1016 16 16 16 0 00-16-16zM336 400a16 16 0 1016 16 16 16 0 00-16-16zM336 320a16 16 0 1016 16 16 16 0 00-16-16zM336 240a16 16 0 1016 16 16 16 0 00-16-16z"
          />
        </svg>
      )
    case 'searchIcon':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 18.089 18.089"
        >
          <g id="search-outline" transform="translate(-62 -62)">
            <path
              id="Path_1"
              data-name="Path 1"
              d="M69.425,64a5.425,5.425,0,1,0,5.425,5.425A5.425,5.425,0,0,0,69.425,64Z"
              fill="none"
              stroke={color}
              strokeMiterlimit="10"
              strokeWidth="4"
            />
            <line
              id="Line_3"
              data-name="Line 3"
              x2="3.789"
              y2="3.789"
              transform="translate(73.472 73.472)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeMiterlimit="10"
              strokeWidth="4"
            />
          </g>
        </svg>
      )
    case 'emptyDatabaseIcon':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          fill="none"
          viewBox="0 0 24 24"
          stroke={color}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4"
          />
        </svg>
      )
    case 'leftPaginationArrowIcon':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 18.536 18.771"
        >
          <g id="arrow-back-outline" transform="translate(2 2.828)">
            <path
              id="Path_37"
              data-name="Path 37"
              d="M106.557,125.115,100,118.557,106.557,112"
              transform="translate(-100 -112)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4"
            />
            <line
              id="Line_7"
              data-name="Line 7"
              x2="13.842"
              transform="translate(0.694 6.557)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4"
            />
          </g>
        </svg>
      )
    case 'rightPaginationArrowIcon':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 18.536 18.771"
        >
          <g
            id="arrow-back-outline"
            transform="translate(16.536 15.943) rotate(180)"
          >
            <path
              id="Path_37"
              data-name="Path 37"
              d="M6.557,13.115,0,6.557,6.557,0"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4"
            />
            <line
              id="Line_7"
              data-name="Line 7"
              x2="13.842"
              transform="translate(0.694 6.557)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4"
            />
          </g>
        </svg>
      )
    case 'apartmentsIconOld':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill={color}
          width={width}
          height={height}
          viewBox="0 0 24 24"
        >
          <path d="M20 7.093v-5.093h-3v2.093l3 3zm4 5.907l-12-12-12 12h3v10h7v-5h4v5h7v-10h3zm-5 8h-3v-5h-8v5h-3v-10.26l7-6.912 7 6.99v10.182z" />
        </svg>
      )
    case 'apartmentsIcon':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 24.397 22.844"
        >
          <g
            id="Group_137"
            data-name="Group 137"
            transform="translate(-7.138 -56.187)"
          >
            <path
              id="Path_60"
              data-name="Path 60"
              d="M305.158,192h8.106a2.316,2.316,0,0,1,2.316,2.316v18.528H304V193.158A1.158,1.158,0,0,1,305.158,192Z"
              transform="translate(-290.453 -134.813)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <path
              id="Path_61"
              data-name="Path 61"
              d="M161.313,322.311a1.158,1.158,0,1,1,1-1A1.158,1.158,0,0,1,161.313,322.311Z"
              transform="translate(-139.509 -253.553)"
              fill={color}
            />
            <path
              id="Path_62"
              data-name="Path 62"
              d="M0,0H22.4"
              transform="translate(8.138 78.03)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeWidth="2"
            />
          </g>
        </svg>
      )

    case 'heatingGroupIcon':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 469.333 511.99"
        >
          <g id="heater" transform="translate(-21.328 0)">
            <g id="Group_28" data-name="Group 28">
              <g id="Group_27" data-name="Group 27">
                <path
                  id="Path_14"
                  data-name="Path 14"
                  d="M437.328,170.655H74.661a53.393,53.393,0,0,0-53.333,53.333v192a53.394,53.394,0,0,0,53.333,53.333H437.328a53.393,53.393,0,0,0,53.333-53.333v-192A53.394,53.394,0,0,0,437.328,170.655Zm32,245.334a32.039,32.039,0,0,1-32,32H74.661a32.039,32.039,0,0,1-32-32v-192a32.039,32.039,0,0,1,32-32H437.328a32.039,32.039,0,0,1,32,32Z"
                  fill={color}
                />
              </g>
            </g>
            <g id="Group_30" data-name="Group 30">
              <g id="Group_29" data-name="Group 29">
                <path
                  id="Path_15"
                  data-name="Path 15"
                  d="M95.994,213.322a10.671,10.671,0,0,0-10.667,10.667v192a10.667,10.667,0,0,0,21.334,0v-192A10.671,10.671,0,0,0,95.994,213.322Z"
                  fill={color}
                />
              </g>
            </g>
            <g id="Group_32" data-name="Group 32">
              <g id="Group_31" data-name="Group 31">
                <path
                  id="Path_16"
                  data-name="Path 16"
                  d="M159.994,213.322a10.671,10.671,0,0,0-10.667,10.667v192a10.667,10.667,0,1,0,21.334,0v-192A10.671,10.671,0,0,0,159.994,213.322Z"
                  fill={color}
                />
              </g>
            </g>
            <g id="Group_34" data-name="Group 34">
              <g id="Group_33" data-name="Group 33">
                <path
                  id="Path_17"
                  data-name="Path 17"
                  d="M223.994,213.322a10.671,10.671,0,0,0-10.667,10.667v192a10.667,10.667,0,0,0,21.334,0v-192A10.671,10.671,0,0,0,223.994,213.322Z"
                  fill={color}
                />
              </g>
            </g>
            <g id="Group_36" data-name="Group 36">
              <g id="Group_35" data-name="Group 35">
                <path
                  id="Path_18"
                  data-name="Path 18"
                  d="M287.994,213.322a10.671,10.671,0,0,0-10.667,10.667v192a10.667,10.667,0,0,0,21.334,0v-192A10.671,10.671,0,0,0,287.994,213.322Z"
                  fill={color}
                />
              </g>
            </g>
            <g id="Group_38" data-name="Group 38">
              <g id="Group_37" data-name="Group 37">
                <path
                  id="Path_19"
                  data-name="Path 19"
                  d="M351.994,213.322a10.671,10.671,0,0,0-10.667,10.667v192a10.667,10.667,0,0,0,21.334,0v-192A10.671,10.671,0,0,0,351.994,213.322Z"
                  fill={color}
                />
              </g>
            </g>
            <g id="Group_40" data-name="Group 40">
              <g id="Group_39" data-name="Group 39">
                <path
                  id="Path_20"
                  data-name="Path 20"
                  d="M415.994,213.322a10.671,10.671,0,0,0-10.667,10.667v192a10.667,10.667,0,0,0,21.334,0v-192A10.671,10.671,0,0,0,415.994,213.322Z"
                  fill={color}
                />
              </g>
            </g>
            <g id="Group_42" data-name="Group 42">
              <g id="Group_41" data-name="Group 41">
                <path
                  id="Path_21"
                  data-name="Path 21"
                  d="M117.328,447.989a10.671,10.671,0,0,0-10.667,10.667v42.667a10.667,10.667,0,0,0,21.334,0V458.656A10.671,10.671,0,0,0,117.328,447.989Z"
                  fill={color}
                />
              </g>
            </g>
            <g id="Group_44" data-name="Group 44">
              <g id="Group_43" data-name="Group 43">
                <path
                  id="Path_22"
                  data-name="Path 22"
                  d="M394.661,447.989a10.671,10.671,0,0,0-10.667,10.667v42.667a10.667,10.667,0,0,0,21.334,0V458.656A10.67,10.67,0,0,0,394.661,447.989Z"
                  fill={color}
                />
              </g>
            </g>
          </g>
        </svg>
      )
    case 'logsIcon':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill={color}
          width={width}
          height={height}
          viewBox="0 0 24 24"
        >
          <path d="M24 12c0 6.627-5.373 12-12 12s-12-5.373-12-12h2c0 5.514 4.486 10 10 10s10-4.486 10-10-4.486-10-10-10c-2.777 0-5.287 1.141-7.099 2.977l2.061 2.061-6.962 1.354 1.305-7.013 2.179 2.18c2.172-2.196 5.182-3.559 8.516-3.559 6.627 0 12 5.373 12 12zm-13-6v8h7v-2h-5v-6h-2z" />
        </svg>
      )
    case 'closeXIcon':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          width={width}
          height={height}
          stroke={color}
          viewBox="0 0 24 24"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      )
    case 'logoutArrowIcon':
      return (
        <svg
          fill={color}
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 24 24"
        >
          <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
        </svg>
      )
    case 'lockIcon':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          fill="none"
          viewBox="0 0 24 24"
          stroke={color}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
          />
        </svg>
      )
    case 'userIcon':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          fill="none"
          viewBox="0 0 24 24"
          stroke={color}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
          />
        </svg>
      )
    case 'eyeIcon':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          fill="none"
          viewBox="0 0 24 24"
          stroke={color}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
          />
        </svg>
      )
    case 'eyeOffIcon':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          fill="none"
          viewBox="0 0 24 24"
          stroke={color}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
          />
        </svg>
      )
    case 'plusIcon':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          fill="none"
          viewBox="0 0 24 24"
          stroke={color}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
          />
        </svg>
      )
    case 'roundedPlusIcon':
      return (
        <svg
          width={width}
          height={height}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke={color}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      )
    case 'roundedXIcon':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          fill="none"
          viewBox="0 0 24 24"
          stroke={color}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      )
    case 'banIcon':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          fill="none"
          viewBox="0 0 24 24"
          stroke={color}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
          />
        </svg>
      )
    case 'buildingIcon':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          fill="none"
          viewBox="0 0 24 24"
          stroke={color}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
          />
        </svg>
      )
    case 'exclamationIcon':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          fill="none"
          viewBox="0 0 24 24"
          stroke={color}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
          />
        </svg>
      )
    case 'dhwIcon':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 325.252 447.518"
        >
          <g
            id="Group_12"
            data-name="Group 12"
            transform="translate(-4799.341 -28)"
          >
            <g id="water-outline" transform="translate(4815.341 44)">
              <path
                id="Path_15"
                data-name="Path 15"
                d="M405.253,324.816c0,89.982-56.645,146.626-146.626,146.626S112,414.8,112,324.816C112,228.257,217.113,97.9,249.35,60.2a12.219,12.219,0,0,1,18.562,0C300.14,97.9,405.253,228.257,405.253,324.816Z"
                transform="translate(-112 -55.925)"
                fill="none"
                stroke={color}
                strokeMiterlimit="10"
                strokeWidth="32"
              />
            </g>
            <g id="thermometer-outline" transform="translate(4906.18 147.113)">
              <path
                id="Path_16"
                data-name="Path 16"
                d="M243.682,185.136V59.894A27.894,27.894,0,0,0,215.788,32h0a27.894,27.894,0,0,0-27.894,27.894V185.136a4.649,4.649,0,0,1-2.156,3.917,56.665,56.665,0,0,0-25.68,50.017,55.787,55.787,0,0,0,111.517-2.516C271.584,217.389,243.684,186.728,243.682,185.136Z"
                transform="translate(-160.002 -32)"
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="32"
              />
            </g>
          </g>
        </svg>
      )
    case 'co2Icon':
      return (
        <svg width={width} height={height} viewBox="0 0 512 512">
          <g fill={color} id="Group_2" data-name="Group 2">
            <g fill={color} id="Group_1" data-name="Group 1">
              <path
                fill={color}
                id="Path_2"
                data-name="Path 2"
                d="M100,340c15.563,0,28.469,10.24,31.384,24.9a20,20,0,0,0,39.233-7.8A70.637,70.637,0,0,0,145.749,316,72.987,72.987,0,0,0,100,300a72.081,72.081,0,0,0-72,72v68a72.007,72.007,0,0,0,142.073,16.6,20,20,0,0,0-38.93-9.2A32.006,32.006,0,0,1,68,440V372A32.036,32.036,0,0,1,100,340Z"
              />
              <path
                fill={color}
                id="Path_3"
                data-name="Path 3"
                d="M462,149.646V136.322C462,61.154,400.846,0,325.678,0A136.376,136.376,0,0,0,199.47,84.751,88.972,88.972,0,0,0,70,164v18.188C29.978,191.163,0,226.68,0,269c0,2.863.134,5.746.4,8.569a20,20,0,1,0,39.826-3.717C40.075,272.259,40,270.627,40,269c0-27.019,22.36-49,49.846-49H90a20,20,0,0,0,20-20V164a48.986,48.986,0,0,1,85.742-32.4A20,20,0,0,0,230.5,121.43C237.693,75.007,278.611,40,325.678,40A96.431,96.431,0,0,1,422,136.322v24.6a20,20,0,0,0,10.624,17.666A73.921,73.921,0,0,1,472,244a72.527,72.527,0,0,1-23.651,53.713,20,20,0,1,0,27.174,29.352A112.111,112.111,0,0,0,512,244,113.79,113.79,0,0,0,462,149.646Z"
              />
              <path
                fill={color}
                id="Path_4"
                data-name="Path 4"
                d="M271,300a72.081,72.081,0,0,0-72,72v68a72,72,0,0,0,144,0V372A72.081,72.081,0,0,0,271,300Zm32,140a32,32,0,0,1-64,0V372a32,32,0,0,1,64,0Z"
              />
              <path
                fill={color}
                id="Path_5"
                data-name="Path 5"
                d="M455,472H440.675c29.216-33.117,33.036-49.442,33.036-60a50.057,50.057,0,0,0-50-50c-.12,0-.236.016-.355.018S423.12,362,423,362a50.057,50.057,0,0,0-50,50,20,20,0,0,0,40,0,10.134,10.134,0,0,1,10-10c.12,0,.236-.016.355-.018s.235.018.355.018a10.139,10.139,0,0,1,10,9.79c-.2,1.029-2.659,11.331-26.191,37.282-13.94,15.374-27.811,27.984-27.945,28.106A20,20,0,0,0,393,512h62a20,20,0,0,0,0-40Z"
              />
            </g>
          </g>
        </svg>
      )
    case 'tempOffsetIcon':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          width={width}
          height={height}
          viewBox="0 0 256 256"
          xmlSpace="preserve"
        >
          <g transform="translate(128 128) scale(0.72 0.72)">
            <g transform="translate(-175.05 -175.05000000000004) scale(3.89 3.89)">
              <path
                fill={color}
                d="M 66.508 37.966 h -0.503 c -1.183 0 -2.299 0.287 -3.283 0.795 c -0.575 -3.389 -3.532 -5.978 -7.082 -5.978 h -0.502 c -1.326 0 -2.569 0.361 -3.637 0.99 c -1.021 -2.724 -3.652 -4.668 -6.729 -4.668 H 44.27 c -1.142 0 -2.223 0.268 -3.183 0.744 V 18.819 c 0 -3.96 -3.222 -7.183 -7.183 -7.183 h -0.502 c -3.96 0 -7.183 3.222 -7.183 7.183 v 27.414 l -3.479 2.641 c -2.638 2.003 -4.311 4.917 -4.71 8.205 c -0.398 3.289 0.529 6.519 2.612 9.094 l 10.604 13.109 v 5.273 c 0 3.002 2.442 5.444 5.444 5.444 h 26.334 c 3.002 0 5.444 -2.442 5.444 -5.444 l 0.001 -5.009 c 3.37 -4.203 5.218 -9.433 5.218 -14.809 v -19.59 C 73.69 41.188 70.468 37.966 66.508 37.966 z M 69.69 64.738 c 0 4.674 -1.683 9.215 -4.738 12.787 c -0.311 0.362 -0.48 0.823 -0.48 1.3 v 5.73 c 0 0.797 -0.647 1.444 -1.444 1.444 H 36.693 c -0.796 0 -1.444 -0.647 -1.444 -1.444 v -5.981 c 0 -0.458 -0.157 -0.902 -0.445 -1.258 l -11.05 -13.659 c -1.396 -1.727 -2.019 -3.892 -1.751 -6.097 c 0.267 -2.204 1.389 -4.158 3.157 -5.501 l 1.06 -0.804 v 6.684 c 0 1.104 0.896 2 2 2 s 2 -0.896 2 -2 V 47.246 c 0 -0.014 0 -0.028 0 -0.042 V 18.819 c 0 -1.755 1.428 -3.183 3.183 -3.183 h 0.502 c 1.755 0 3.183 1.428 3.183 3.183 v 17.469 c 0 0 0 0 0 0.001 s 0 0 0 0.001 l 0.011 14.531 c 0.001 1.104 0.896 1.998 2 1.998 c 0 0 0.001 0 0.001 0 c 1.104 -0.001 2 -0.896 1.999 -2.002 l -0.011 -14.528 c 0 -1.754 1.428 -3.182 3.183 -3.182 h 0.502 c 1.755 0 3.183 1.428 3.183 3.182 v 3.678 c 0 0 0 0.001 0 0.001 c 0 0 0 0.001 0 0.001 l 0.011 10.853 c 0.001 1.104 0.896 1.998 2 1.998 c 0.001 0 0.001 0 0.002 0 c 1.104 -0.001 1.999 -0.897 1.998 -2.002 l -0.011 -10.851 c 0 -1.754 1.428 -3.182 3.183 -3.182 h 0.502 c 1.755 0 3.183 1.428 3.183 3.182 v 4.602 c -0.008 0.072 -0.022 0.143 -0.021 0.217 l 0.011 6.037 c 0.002 1.103 0.897 1.996 2 1.996 c 0.001 0 0.002 0 0.004 0 c 1.104 -0.002 1.998 -0.899 1.996 -2.004 l -0.01 -5.463 c 0.007 -0.068 0.02 -0.133 0.02 -0.203 c 0 -1.755 1.428 -3.183 3.183 -3.183 h 0.503 c 1.754 0 3.182 1.428 3.182 3.183 V 64.738 z"
                transform=" matrix(1 0 0 1 0 0) "
                strokeLinecap="round"
              />
              <path
                fill={color}
                d="M 48.333 19.011 c -1.104 0 -2 -0.896 -2 -2 C 46.333 9.837 40.496 4 33.322 4 C 26.147 4 20.31 9.837 20.31 17.011 c 0 1.104 -0.896 2 -2 2 s -2 -0.896 -2 -2 C 16.31 7.631 23.942 0 33.322 0 c 9.38 0 17.012 7.631 17.012 17.011 C 50.333 18.116 49.438 19.011 48.333 19.011 z"
                transform=" matrix(1 0 0 1 0 0) "
                strokeLinecap="round"
              />
            </g>
          </g>
        </svg>
      )

    case 'favoritesIcon':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 30.073 27.577"
        >
          <path
            id="Path_2"
            data-name="Path 2"
            d="M0,8.423H9.566L12.252,0l2.783,8.423h8.672l-7.1,5.134,3.006,8.117L12.252,16.5,4.918,21.674l2.708-8.117Z"
            transform="translate(3.276 3.239)"
            fill="none"
            stroke={color}
            strokeWidth="2"
          />
        </svg>
      )

    case 'favoritesIconFilled':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 30.073 27.577"
        >
          <path
            id="Path_2"
            data-name="Path 2"
            d="M0,8.423H9.566L12.252,0l2.783,8.423h8.672l-7.1,5.134,3.006,8.117L12.252,16.5,4.918,21.674l2.708-8.117Z"
            transform="translate(3.276 3.239)"
            fill={color}
            stroke={color}
            strokeWidth="2"
          />
        </svg>
      )

    case 'lock':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 12.25 14"
        >
          <path
            id="Icon_awesome-lock"
            data-name="Icon awesome-lock"
            d="M10.938,6.125h-.656V4.156a4.156,4.156,0,0,0-8.312,0V6.125H1.313A1.313,1.313,0,0,0,0,7.438v5.25A1.313,1.313,0,0,0,1.313,14h9.625a1.313,1.313,0,0,0,1.313-1.312V7.438A1.313,1.313,0,0,0,10.938,6.125Zm-2.844,0H4.156V4.156a1.969,1.969,0,0,1,3.938,0Z"
            fill={color}
          />
        </svg>
      )

    case 'lock-open':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 15.75 14"
        >
          <path
            id="Icon_awesome-lock-open"
            data-name="Icon awesome-lock-open"
            d="M11.58,0A4.183,4.183,0,0,0,7.438,4.2V6.125H1.313A1.313,1.313,0,0,0,0,7.438v5.25A1.313,1.313,0,0,0,1.313,14h9.625a1.313,1.313,0,0,0,1.313-1.312V7.438a1.313,1.313,0,0,0-1.312-1.312H9.625V4.181a1.969,1.969,0,1,1,3.938-.025V6.344A.655.655,0,0,0,14.219,7h.875a.655.655,0,0,0,.656-.656V4.156A4.161,4.161,0,0,0,11.58,0Z"
            transform="translate(0 0)"
            fill={color}
          />
        </svg>
      )
    default:
      break
  }
  return ''
}
