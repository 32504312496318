export const navigationArray = [
  { title: 'login', index: 'loginBtnText' },
  { title: 'home', index: 'homeBtnText' },
  { title: 'favorites', index: 'favoritesBtnText' },
  { title: 'project', index: 'projectBtnText' },
  { title: 'emr', index: 'emrBtnText' },
  // { title: "alerts", index: "alertsBtnText" },
  { title: 'apartments', index: 'apartmentsBtnText' },
  { title: 'heating-groups', index: 'heatingGroupsBtnText' },
  { title: 'log', index: 'logBtnText' },
  { title: 'building', index: 'buildingBtnText' },
  { title: 'apartment', index: 'apartmentBtnText' },
  { title: 'temporary-offset', index: 'temporaryOffsetBtnText' },
]

export const menuArray = [
  [
    {
      title: 'home',
      index: 'projectsBtnText',
      svgSettings: {
        name: 'buildingsIcon',
        width: 25,
        height: 25,
        color: '#fff',
      },
    },
    {
      title: 'favorites',
      index: 'favoritesBtnText',
      svgSettings: {
        name: 'favoritesIcon',
        width: 25,
        height: 25,
        color: '#fff',
      },
    },
    {
      title: 'project',
      index: 'projectBtnText',
      svgSettings: {
        name: 'buildingIcon',
        width: 25,
        height: 25,
        color: '#fff',
      },
    },
    {
      title: 'emr',
      index: 'emrBtnText',
      svgSettings: { name: 'emrIcon', width: 25, height: 25, color: '#fff' },
    },
    // {
    //   title: "alerts",
    //   index: "alertsBtnText",
    //   svgSettings: { name: "alarmIcon", width: 25, height: 25, color: "#fff" },
    // },
    {
      title: 'heating-groups',
      index: 'heatingGroupsBtnText',
      svgSettings: {
        name: 'heatingGroupIcon',
        width: 25,
        height: 25,
        color: '#fff',
      },
    },
    {
      title: 'log',
      index: 'logBtnText',
      svgSettings: {
        name: 'logsIcon',
        width: 25,
        height: 25,
        color: '#fff',
      },
    },
  ],
  [
    {
      title: 'home',
      index: 'buildingsBtnText',
      svgSettings: {
        name: 'buildingsIcon',
        width: 25,
        height: 25,
        color: '#fff',
      },
    },
    {
      title: 'favorites',
      index: 'favoritesBtnText',
      svgSettings: {
        name: 'favoritesIcon',
        width: 25,
        height: 25,
        color: '#fff',
      },
    },
    {
      title: 'building',
      index: 'buildingBtnText',
      svgSettings: {
        name: 'buildingIcon',
        width: 25,
        height: 25,
        color: '#fff',
      },
    },
    {
      title: 'emr',
      index: 'emrBtnText',
      svgSettings: { name: 'emrIcon', width: 25, height: 25, color: '#fff' },
    },
    // {
    //   title: "alerts",
    //   index: "alertsBtnText",
    //   svgSettings: { name: "alarmIcon", width: 25, height: 25, color: "#fff" },
    // },
    {
      title: 'apartments',
      index: 'apartmentsBtnText',
      svgSettings: {
        name: 'apartmentsIcon',
        width: 25,
        height: 25,
        color: '#fff',
      },
    },
    {
      title: 'heating-groups',
      index: 'heatingGroupsBtnText',
      svgSettings: {
        name: 'heatingGroupIcon',
        width: 25,
        height: 25,
        color: '#fff',
      },
    },
    {
      title: 'log',
      index: 'logBtnText',
      svgSettings: {
        name: 'logsIcon',
        width: 25,
        height: 25,
        color: '#fff',
      },
    },
    {
      title: 'temporary-offset',
      index: 'temporaryOffsetBtnText',
      svgSettings: {
        name: 'tempOffsetIcon',
        width: 25,
        height: 25,
        color: '#fff',
      },
    },
  ],
]

export const languagesArray = [
  'englishText',
  'frenchText',
  'germanText',
  'czechText',
]
