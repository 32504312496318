import React, { useEffect, useState, useContext, lazy } from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect, withRouter } from 'react-router'
// ACTIONS
import { actions, fetchingError } from '../../redux/actions/auth'
import { languageContext } from '../../languageContext'
import textsData from '../../staticTexts'

import Main from '../header/Main'
import Modal from '../modal/Modal'

//* Calling all the app pages
import PrivateRoute from '../../routers/PrivateRoute'
const home = lazy(() => import('../../pages/home/home'))
const building = lazy(() => import('../../pages/building/building'))
const alerts = lazy(() => import('../../pages/alerts/alerts'))
const apartment = lazy(() => import('../../pages/apartment/apartment'))
const apartments = lazy(() => import('../../pages/apartments/apartments'))
const favorites = lazy(() => import('../../pages/favorites/favorites'))

const emr = lazy(() => import('../../pages/emr/emr'))
const project = lazy(() => import('../../pages/project/project'))
const heatingGroups = lazy(() =>
  import('../../pages/heatingGroups/heatingGroups'),
)

const TemporaryOffset = lazy(() =>
  import('../../pages/temporaryOffset/temporaryOffset'),
)

const log = lazy(() => import('../../pages/log/logs'))
const login = lazy(() => import('../../pages/auth/login'))

//* Creating routes for all the pages
function Layout({ closePopup, popupState, logout }) {
  const { value } = useContext(languageContext)

  const [texts] = useState(textsData[4])
  const [online, setOnline] = useState(navigator.onLine)
  const [warningOpen, setWarningOpen] = useState(false)

  const handleOnline = () => {
    setOnline(true)
    if (warningOpen === false) {
      setWarningOpen(false)
    }
  }

  const handleOffline = () => {
    setOnline(false)
    setWarningOpen(true)
  }

  useEffect(() => {
    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [])

  const handleClose = () => {
    closePopup()
    logout()
  }
  return (
    <div className="layout-container">
      <div className="main-web">
        <Main />
        <div className="pages-container">
          {/* {online === false && warningOpen === true &&  */}
          {/* } */}
          <Switch>
            <Route exact path="/login" component={login} />
            <Route exact path="/" render={() => <Redirect to="/home" />} />
            <PrivateRoute exact path="/home" component={home} />
            <PrivateRoute exact path="/favorites" component={favorites} />
            <PrivateRoute exact path="/building" component={building} />
            <PrivateRoute exact path="/alerts" component={alerts} />
            <PrivateRoute exact path="/apartment" component={apartment} />
            <PrivateRoute exact path="/apartments" component={apartments} />
            <PrivateRoute exact path="/emr" component={emr} />
            <PrivateRoute exact path="/project" component={project} />
            <PrivateRoute
              exact
              path="/heating-groups"
              component={heatingGroups}
            />
            <PrivateRoute exact path="/log" component={log} />
            <PrivateRoute
              exact
              path="/temporary-offset"
              component={TemporaryOffset}
            />

            <Route render={() => <Redirect to="/error" />} />
          </Switch>
          {online === false && warningOpen === true && (
            <Modal
              title={'Warning'}
              click={() => {
                setWarningOpen(false)
              }}
            >
              <div className="grid place-items-center text-lg text-center text-ecco2blue-100 p-6">
                <div>{texts[value].connectionErrMsg}</div>
              </div>
            </Modal>
          )}
          {popupState && (
            <Modal
              title={'Error'}
              click={() => {
                handleClose()
              }}
            >
              <div className="grid place-items-center text-lg text-center text-ecco2blue-100 p-6">
                <div>An error has occured</div>
                <div
                  onClick={() => handleClose()}
                  className="p-2 rounded-lg bg-gray-200 text-ecco2blue-100 ml-4"
                >
                  Retry
                </div>
              </div>
            </Modal>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  popupState: state.auth.popupState,
})

const mapDispatchToProps = (dispatch) => ({
  closePopup: (data) => dispatch(fetchingError.fetchError(data)),
  logout: () => dispatch(actions.logOut()),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout))
