import fetch from 'isomorphic-fetch'

let { BASE_URL } = require('./config')

export const authenticate = async (userData) => {
  window.onbeforeunload = null
  let refresher = false
  let timer = false
  try {
    const controller = new AbortController()
    window.onbeforeunload = function () {
      refresher = true
      controller.abort()
    }

    setTimeout(() => {
      timer = true
      controller.abort()
    }, 300000)
    let response = await fetch(`${BASE_URL}auth/caretaker`, {
      signal: controller.signal,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Basic ${window.btoa(
          `${userData.username}:${userData.password}`,
        )}`,
      },
    })

    if (!response.ok) {
      let errorResData = await response
      let parsedError = await response.text()
      let stringified = parsedError ? await JSON.parse(parsedError) : null
      let error = {
        error: true,
        serverError: true,
        refreshError: false,
        connError: false,
        message: errorResData.statusText,
        url: errorResData.url,
        code: stringified?.payload?.length > 0 ? 402 : errorResData.status,
      }
      return error
    }

    // returning parsed response
    let resData = await response.json()
    return resData?.payload
  } catch (err) {
    let error = {
      error: true,
      serverError: false,
      refreshError: refresher,
      connError: timer,
      code: 998,
    }
    return error
  }
}

// API Responsible to get access rights
export const getAccessRights = async (projectId, userData) => {
  window.onbeforeunload = null
  let refresher = false
  let timer = false
  try {
    const controller = new AbortController()
    window.onbeforeunload = function () {
      refresher = true
      controller.abort()
    }

    setTimeout(() => {
      timer = true
      controller.abort()
    }, 300000)
    let response = await fetch(`${BASE_URL}user/accessrights`, {
      signal: controller.signal,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Basic ${window.btoa(
          `${userData.username}:${userData.password}`,
        )}`,
      },
      body: JSON.stringify({
        request: {
          projectIds: [projectId],
        },
      }),
    })

    if (!response.ok) {
      let errorResData = await response

      let error = {
        error: true,
        serverError: true,
        refreshError: false,
        connError: false,
        message: errorResData.statusText,
        url: errorResData.url,
        code: errorResData.status,
      }
      return error
    }

    // returning parsed response
    let resData = await response.json()
    return resData?.payload
  } catch (err) {
    let error = {
      error: true,
      serverError: false,
      refreshError: refresher,
      connError: timer,
      code: 998,
    }
    return error
  }
}
